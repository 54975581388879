/* line 1, ../../source/scss/ng-img-crop.scss */
img-crop {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
/* line 7, ../../source/scss/ng-img-crop.scss */
img-crop canvas {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* mobile webkit */
}
