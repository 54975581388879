.ng-table th {
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ng-table th.sortable {
    cursor: pointer; }
    .ng-table th.sortable .sort-indicator {
      padding-right: 18px;
      position: relative; }
      .ng-table th.sortable .sort-indicator:after, .ng-table th.sortable .sort-indicator:before {
        content: "";
        border-width: 0 4px 4px;
        border-style: solid;
        border-color: #000 transparent;
        visibility: visible;
        right: 5px;
        top: 50%;
        position: absolute;
        opacity: .3;
        margin-top: -4px; }
      .ng-table th.sortable .sort-indicator:before {
        margin-top: 2px;
        border-bottom: none;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #000; }
    .ng-table th.sortable .sort-indicator:hover:after, .ng-table th.sortable .sort-indicator:hover:before {
      opacity: 1;
      visibility: visible; }
    .ng-table th.sortable.sort-desc, .ng-table th.sortable.sort-asc {
      background-color: rgba(141, 192, 219, 0.25);
      text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75); }
      .ng-table th.sortable.sort-desc .sort-indicator:after, .ng-table th.sortable.sort-asc .sort-indicator:after {
        margin-top: -2px; }
      .ng-table th.sortable.sort-desc .sort-indicator:before, .ng-table th.sortable.sort-asc .sort-indicator:before {
        visibility: hidden; }
    .ng-table th.sortable.sort-asc .sort-indicator:after, .ng-table th.sortable.sort-asc .sort-indicator:hover:after {
      visibility: visible;
      filter: alpha(opacity=60);
      -khtml-opacity: 0.6;
      -moz-opacity: 0.6;
      opacity: 0.6; }
    .ng-table th.sortable.sort-desc .sort-indicator:after {
      border-bottom: none;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000;
      visibility: visible;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      filter: alpha(opacity=60);
      -khtml-opacity: 0.6;
      -moz-opacity: 0.6;
      opacity: 0.6; }
  .ng-table th.filter .input-filter {
    margin: 0;
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.ng-table .ng-table-group-header th {
  text-align: left; }

.ng-table .ng-table-group-selector {
  display: block; }

.ng-table .ng-table-group-close, .ng-table .ng-table-group-toggle {
  float: right; }

.ng-table .ng-table-group-toggle {
  margin-right: 5px; }

@media only screen and (max-width: 800px) {
  .ng-table-responsive {
    border-bottom: 1px solid #999; }
    .ng-table-responsive tr {
      border-top: 1px solid #999;
      border-left: 1px solid #999;
      border-right: 1px solid #999; }
    .ng-table-responsive td:before {
      position: absolute;
      padding: 8px;
      left: 0;
      top: 0;
      width: 50%;
      white-space: nowrap;
      text-align: left;
      font-weight: bold; }
    .ng-table-responsive thead tr th {
      text-align: left; }
    .ng-table-responsive thead tr.ng-table-filters th {
      padding: 0; }
      .ng-table-responsive thead tr.ng-table-filters th form > div {
        padding: 8px; }
    .ng-table-responsive td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align: left; }
      .ng-table-responsive td:before {
        content: attr(data-title-text); }
    .ng-table-responsive, .ng-table-responsive thead, .ng-table-responsive tbody, .ng-table-responsive th, .ng-table-responsive td, .ng-table-responsive tr {
      display: block; } }

.ng-table-pagination {
  margin-top: 0; }

.ng-table-group-selector:before,
.ng-table-group-selector:after,
.filter:before,
.filter:after {
  display: table;
  content: " "; }

.ng-table-group-selector:after,
.filter:after {
  clear: both; }

.filter > .filter-cell {
  float: left;
  box-sizing: border-box; }

.filter-horizontal > .filter-cell {
  padding: 0 2px; }

.filter-horizontal > .filter-cell:first-child {
  padding-left: 0; }

.filter-horizontal > .filter-cell:last-child, .filter-horizontal > .filter-cell.last {
  padding-right: 0; }

.s12 {
  width: 100%; }

.s11 {
  width: 91.66666666666666%; }

.s10 {
  width: 83.33333333333334%; }

.s9 {
  width: 75%; }

.s8 {
  width: 66.66666666666666%; }

.s7 {
  width: 58.333333333333336%; }

.s6 {
  width: 50%; }

.s5 {
  width: 41.66666666666667%; }

.s4 {
  width: 33.33333333333333%; }

.s3 {
  width: 25%; }

.s2 {
  width: 16.666666666666664%; }

.s1 {
  width: 8.333333333333332%; }

@media all and (max-width: 468px) {
  .s12, .s11, .s10, .s9, .s8, .s7, .s6, .s5, .s4, .s3, .s2, .s1 {
    width: 100%; }
  .filter > .filter-cell {
    padding: 0px; } }

/*# sourceMappingURL=ng-table.css.map*/